import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import { COUNTRY } from '../../configs/env';
import { PageContext } from '../../types/types';
import PageNotFound from '../../pages_/404/404.page';
import { NewStandardPageLayout } from '../../layouts/newStadardPage.layout';
import { PageSection } from '../../components/layout/_/PageSection/PageSection.component';
import { css } from '../../helpers/css.theme';
import { postContentCss } from '../blog/single/components/postContent.css';
import { Container } from '../../layouts/main.layout';

interface WpPageNodeI {
  node: {
    id: string;
    slug: string;
    date: string;
    title: string;
    content: string;
  };
}

interface CodexPageDataPropsI {
  data: {
    allWordpressPage: {
      edges: Array<WpPageNodeI>;
    };
  };
  pageContext: PageContext;
}

const CodexPage = (props: CodexPageDataPropsI): React.FC | ReactElement => {
  const { t } = useTranslation();

  const meta = {
    title: t('meta_CodexPageTitle'),
    description: t('meta_CodexPageDescription'),
  };

  let codexPageContent = props.data.allWordpressPage.edges.find((edge) => {
    return edge.node.slug === `kodeks-ponasanja-partnera-${COUNTRY}`;
  });

  if (COUNTRY === 'ba' || COUNTRY === 'hr') {
    codexPageContent = props.data.allWordpressPage.edges.find((edge) => {
      return edge.node.slug === 'kodeks-ponasanja-partnera-ba-hr';
    });
  }
  if (COUNTRY === 'me') {
    codexPageContent = props.data.allWordpressPage.edges.find((edge) => {
      return edge.node.slug === 'kodeks-ponasanja-partnera-rs';
    });
  }

  if (codexPageContent) {
    return (
      <NewStandardPageLayout
        lang={props.pageContext.lang}
        mainHeading={t('codex_MainHeading')}
        subText={t('codex_MainSubText')}
        backgroundImage="bg/partnership.jpg"
        backgroundImageMobile="bg/partnership-mobile@3x.jpg"
        storeBadgeIconsPartners
        meta={meta}
      >
        <PageSection
          css={css`
            margin-top: 75px;
          `}
        >
          <Container style={{ paddingTop: 60 }}>
            <div css={postContentCss} dangerouslySetInnerHTML={{ __html: codexPageContent.node.content }} />
          </Container>
        </PageSection>
      </NewStandardPageLayout>
    );
  }

  return <PageNotFound />;
};

export const allPostsQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          title
          slug
          date
          content
          id
        }
      }
    }
  }
`;

export default CodexPage;
